.computerPatient {
	display: flex;
	flex-direction: column;
	width: 27.34%;
	height: 100%;
	background-color: #fff;

	-webkit-box-shadow: -10px 0px 5px -2px #CCCCCC; 
	box-shadow: -10px 0px 5px -2px #CCCCCC;
}

.computerPatientHeader {
	position: relative;
	display: flex;
	width: 100%;
	height: min(4vh, 50px);
	background-color: #CCCCCC;
	align-items: center;
}

.computerPatientHeader.green {
	background-color: #30BF4C;
}
.computerPatientHeader.blue {
	background-color: #00AAFF;
}

.computerPatientHeader > img {
	height: min(2.5vh, 30px);
	margin: 10px;
}

.computerPatientHeader > span {
	font-size: min(1vh, 20px);
	color: #fff;
	font-weight: bold;
}

.computerPatientHeaderEmpty {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: -25px;
	right: 10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border: 1px solid #333333;
	border-radius: 2px;
}

.computerPatientInfoEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	font-size: min(1vh, 16px);
	font-weight: bold;
	text-align: center;
	color: #CCCCCC;
}
.computerPatientInfoEmpty > img {
	width: 30px;
	margin-bottom: 5px;
}

.computerPatientInfo {
	font-size: min(0.9vh, 12px);
}

.computerPatientInfoAge {
	display: flex;
	margin: 10px 5%;
}

.computerPatientInfoAge > span:first-of-type {
	margin-right: 15%;
}

.computerPatientInfoAddress {
	display: flex;
	flex-direction: column;
	margin: 0 5%;
	padding: 10px;
	border: 1px solid #CCCCCC;
	background-color: #F2F4F6;
}

.computerPatientInfoAddress > div {
	display: flex;
	align-items: center;
}

.computerPatientInfoAddress > div > img {
	max-width: 20px;
	max-height: 100%;
}

.computerPatientInfoAddress > div > span {
	margin-left: 5%;
	font-weight: bold;
}

.computerPatientInfoAddress > span {
	margin-top: 2px;
}

.computerPatientInfoPrice {
	display: flex;
	margin: 10px 5%;
}

.computerPatientInfoPrice > div {
	display: flex;
	align-items: center;
	margin-right: 15px;
}
.computerPatientInfoPrice > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F2F4F6;
	padding: 5px;
	margin-right: 5px;
}
.computerPatientInfoPrice > div > div > img {
	width: 80%;
}

.computerPatientInfoButtonContainer {
	display: flex;
	margin: 10px 5%;
}

.computerPatientInfoButton {
	display: flex;
	align-items: center;
	background-color: #CCCCCC;
	border-radius: 5px;
	padding: 5px;
}
.computerPatientInfoButton > img {
	width: 20px;
}
.computerPatientInfoButton > span {
	margin-left: 5px;
	margin-right: 10px;
	color: #fff;
	font-weight: bold;
}

.computerPatientInfoDpContainer {
	display: flex;
	background-color: #F2F4F6;
	margin-left: 5%;
	font-size: min(0.6vh, 8px);
	font-weight: bold;
}
.computerPatientInfoDp {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.computerPatientInfoDp > div {
	display: flex;
	width: 100%;
	align-items: center;
}
.computerPatientInfoDp > div > img {
	max-width: 100%;
	max-height: 10px;
}

.computerPatientInfoDp > div > div {
	display: flex;
	width: 100%;
	height: 2px;
	margin: 0 3px;
	background-color: #000;
}
.computerPatientInfoDp > span {
	margin: 0 10px;
}

.computerPatientInfoLine {
	display: flex;
	width: 100%;
	height: 2px;
	background-color: #F2F4F6;
}

.computerPatientInfoTitle {
	display: flex;
	font-size: min(1.3vh, 16px);
	font-weight: bold;
	color: #30BF4C;
	margin: 10px 5%;
}

.computerPatientInfoText {
	display: flex;
	font-weight: bold;
	margin-left: 5%;
}

.computerPatientInfoCard {
	display: flex;
	margin: 0px 5%;
	padding: 10px;
	border: 1px solid #CCCCCC;
	background-color: #F2F4F6;
}

.computerPatientInfoRight {
	display: flex;
	margin: 15px 5%;
}

.computerPatientInfoRight > div {
	display: flex;
	flex: 1;
	align-items: center;
}

.computerPatientInfoSwitch {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 35px !important;
	height: 20px;
	margin-left: 5%;
	padding-right: 2px;
	background-color: #30BF4C;
	border-radius: 20px;
}
.computerPatientInfoSwitch > div {
	display: flex;
	height: 16px;
	width: 16px;
	background-color: #fff;
	border-radius: 50%;
}

.computerPatientInfoRight > div:last-of-type {
	border: 1px solid #30BF4C;
	border-radius: 3px;
}
.computerPatientInfoRight > div:last-of-type > span {
	display: flex;
	flex: 1;
	margin: 5px;
}

.computerPatientInfoValid {
	margin: 5px;
	max-height: 30px;
	max-width: 30px;
}
.computerPatientInfoValid > img {
	max-width: 100%;
	max-height: 100%;
}


.computerPatientDate {
	display: flex;
	margin: 0 5%;
}
.computerPatientDate > div {
	flex: 1;
}
.computerPatientDate > div:first-of-type {
	margin-right: 5px;
}
.computerPatientDate > div:last-of-type {
	margin-left: 5px;
}

.computerPatientDateInput {
	display: flex;
	flex: 1;
	margin-top: 2px;
}

.computerPatientDateInput > div:first-of-type {
	display: flex;
	flex: 1;
	align-items: center;
	border: 1px solid #CCCCCC;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 10px;
}
.computerPatientDateInput > div:last-of-type {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #CCCCCC;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #CCCCCC;
	padding: 0 5px;
}

.computerPatientInfoDoctor {
	margin-top: 10%;
}
.computerPatientInfoDoctor > div {
	display: flex;
	margin: 10px 5%;
}

.computerPatientInfoDoctor > div > div {
	display: flex;
	flex: 1;
	align-items: center;
}

.computerPatientInfoDoctor > div > div > img {
	width: 15px;
	height: 15px;
	padding: 5px;
	margin-right: 10px;
	background-color: #CCCCCC;
}

.computerPatientDateFlex {
	margin: 10px 5%;
}
.computerPatientDateFlex .computerPatientDateInput > div {
	border-color: #30BF4C;
}
.computerPatientDateFlex .computerPatientDateInput > div:last-of-type {
	background-color: #30BF4C;
}

.computerPatientInfoCardStep7 {
	display: flex;
	margin: 0px 5%;
	padding: 10px;
}

.computerPatientInfoCardStep7 > div:first-of-type {
	display: flex;
	flex: 1;
}

.computerMenuStep8 {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
}

.computerMenuStep8Title {
	display: flex;
	align-items: center;
	padding: 10px 5%;
	color: #CCCCCC;
}
.computerMenuStep8Title.blue {
	color: #00AAFF;
}
.computerMenuStep8Title > img {
	width: 25px;
}
.computerMenuStep8Title > div:first-of-type {
	display: flex;
	flex: 1;
	margin-left: 5%;
	font-weight: bold;
	font-size: min(1vh, 14px);
}
.computerMenuStep8TitleLast {
	border: 1px solid #CCCCCC;
	padding: 4px;
	border-radius: 3px;
}
.computerMenuStep8TitleLast > img {
	max-width: 25px;
	max-height: 100%;
}

.computerMenuStep8SearchContainer {
	display: flex;
	height: 10vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #F2F4F6;
	color: #F2F4F6;
}
.computerMenuStep8SearchContainer.noBorder {
	border: none;
}
.computerMenuStep8SearchContainer > div > img {
	max-width: 25px;
}

.computerMenuStep8ButtonContainer {
	margin: 0 5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.computerMenuStep8ButtonContainer > div {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border-radius: 5px;
	background-color: #00AAFF;
}
.computerMenuStep8ButtonContainer > div > img {
	width: 30px;
	height: 30px;
	object-fit: contain;
}

.computerMenuStep8Button.shadow {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

.computerMenuStep8ButtonAdd {
	margin: 10px 5%;
	padding: 5px;
	border: 1px solid #00AAFF;
	border-radius: 5px;
	text-align: center;
	color: #00AAFF;
}

.computerMenuStep8Total {
	display: flex;
	margin: 10px 5%;

	font-weight: bold;
}
.computerMenuStep8Total > span:first-of-type {
	display: flex;
	flex: 1;
}