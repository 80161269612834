.computerMain {
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
	background-color: #F2F4F6;
	z-index: 20 !important;
}
.computerMain.blur {
	filter: blur(5px);
}

.computerLeft {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.computerHeader {
	display: flex;
	width: 100%;
	height: min(6vh, 60px);
	background-color: #FBFBFC;
	border-bottom: -1px solid #F2F2F2;
}

.computerMenuCase {
	display: flex;
	flex-direction: column;
	height: min(6vh, 60px);
	width: min(6vh, 60px);
	justify-content: center;
	align-items: center;

	color: #fff;
	text-align: center;
	font-size: min(0.35vw, 5px);
	font-weight: bold;
}

.computerMenuCase > div {
	display: flex;
	width: 40%;
	height: 50%;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.computerMenuCase > img,
.computerMenuCase > div > img {
	max-height: 80%;
	max-width: 80%;
	/* object-fit: contain; */
}
.computerMenuCase.green {
	background-color: #30BF4C;
}
.computerMenuCase.blue {
	background-color: #00AAFF;
}
.computerMenuCase.white {
	background-color: #fff;
}

.computerMenuCase > span {
	margin-top: 9px;
}
.computerMenuCase.blueText {
	color: #00AAFF;
}
.computerMenuCase.greenText {
	color: #30BF4C;
}
.computerMenuCase.greyText {
	color: #999999;
}

.computerHeaderSearch {
	display: flex;
	flex: 1;
	align-items: center;

	color: #999999;
	font-size: min(0.7vw, 14px);
}
.computerHeaderSearch > div {
	margin-left: 17px;
	margin-right: 7px;
	width: min(4vh, 25px);
}
.computerHeaderSearch > div > img {
	max-width: 100%;
	max-height: 100%;
}

.computerBody {
	display: flex;
	flex: 1;
}

.computerMenu {
	display: flex;
	width: min(6vh, 60px);
	height: 100%;
	flex-direction: column;
	background-color: #FFFFFF;
}

.computerMenuBottom {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.computerMenuBottom > img {
	width: min(2.5vh, 30px);
	margin-bottom: 15px;
}

.computerMainContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: #F2F4F6;
}

/* Popup */
.computerErrorPopup,
.computerInfoPopup {
	position: absolute;
	width: 260px;
	padding: 15px 20px;
	/* background-color: #fff;
	border: 2px solid #00AAFF; */
	border-radius: 10px;

	/* background-image: url(/src/ressources/images/global/Rectangle\ 233.png);
	background-size: 100% 100%; */

	border-radius: 20px;
	background-color: #C5DC23;

	font-size: 18px;
	text-align: center;
	font-weight: bold;
	color: #fff;
	/* color: #00AAFF; */
}

/* Step 1 */

/* Header */
.computerContainerListHeader {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-top: 2%;
}
.computerContainerListHeader > div:first-of-type {
	display: flex;
	align-items: center;
	width: 100%;
}

.computerContainerListHeader > div:first-of-type > div:first-of-type,
.computerContainerListHeader > div:first-of-type > div:last-of-type {
	flex: 0.5;
}

.computerContainerListHeaderIcon {
	position: relative;
	display: flex;
	height: min(4vh, 50px);
	width: min(4vh, 50px);
	justify-content: center;
	align-items: center;
	border: 1px solid #CCCCCC;
	border-radius: 50%;
}
.computerContainerListHeaderIcon.active {
	background-color: #30BF4C;
	border-color: #30BF4C;
}
.computerContainerListHeaderIcon.actual {
	border-color: #30BF4C;
	background-color: transparent !important;
}

.computerContainerListHeaderIcon > img {
	max-width: 70%;
	max-height: 70%;
	object-fit: contain;
}

.computerContainerListHeaderLine {
	display: flex;
	flex: 1;
	height: 2px;
	background-color: #CCCCCC;
}
.computerContainerListHeaderLine > div {
	display: flex;
	width: 100%;
	background-color: #30BF4C;

	animation-name: headerLine;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

@keyframes headerLine {
	0% {
		width: 0%;
	}
}

.computerContainerListHeader > div:last-of-type {
	display: flex;
	width: 100%;
	margin-top: 8px;
}
.computerContainerListHeader > div:last-of-type > div {
	display: flex;
	flex: 1;
	justify-content: center;

	font-size: min(0.8vh, 14px);
	font-weight: bold;
	text-align: center;
	color: #CCCCCC;
}

.computerStep1 {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 3%;
	background-color: #FFFFFF;
	border: 1px solid #15C193;
	border-radius: 5px;

	font-size: min(0.8vh, 14px);
	font-weight: bold;
	color: #333333;
}
.computerStep1.blur * {
	filter: blur(9px);
}

.computerStep1Title {
	position: relative;
	padding: 1vh;
	border-bottom: 1px solid #15C193;
}

.computerStep1Container {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.computerStep1Container > div {
	display: flex;
	width: 60%;
	flex-direction: column;
	align-items: center;
}
.computerStep1Container > div > img {
	max-width: 60%;
	max-height: 100%;
	margin-bottom: 10px;
	object-fit: contain;
}

.computerStep1Container > div:last-of-type > img {
	/* max-width: 40%;
	max-height: 80%;
	padding: 3%;
	background-image: url(/src/ressources/images/visu/ipadBackground.png);
	background-size: 100% 100%; */

	opacity: 0.3;
}

.computerStep1Image.active {
	cursor: pointer;
	border-radius: 50%;
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

/* Bottom */

.computerContainerBottom {
	display: flex;
	width: 100%;

	background-color: #454F63;
}

.computerContainerBottom0 {
	display: flex;
	margin-left: 2%;
	justify-content: center;
	padding: min(0.9vh, 15px);
	border-right: 1px solid #fff;
}
.computerContainerBottom0 > div {
	display: flex;
	align-self: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 50%;
	width: min(0.9vh, 15px);
	height: min(0.8vh, 13px);

	font-size: min(0.6vh, 11px);
}

.computerContainerBottomTotal {
	display: flex;
	align-items: center;
	padding: min(0.9vh, 15px);

	font-size: min(0.8vh, 14px);
	font-weight: bold;
	color: #fff;
}
.computerContainerBottomTotal > img {
	max-height: 15px;
	margin-left: 20px;
}

.computerContainerBottomBox {
	position: relative;
	display: flex;
	width: min(15vh, 200px);
	justify-content: center;
	align-items: center;
	background-color: #fff;

	font-size: min(0.8vh, 14px);
	font-weight: bold;
	color: #30BF4C;
	text-align: center;
}

.computerContainerBottomBox.active {
	background-color: #30BF4C;
	color: #fff;
}
.computerContainerBottomBox.border {
	color: #333333;
	border: 2px solid #333333;
	border-bottom: 0px;
}
.computerContainerBottomBox.shadow {
	cursor: pointer;
	z-index: 1;
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

.messageStep1 {
	top: 50%;
	left: calc(50% - 250px);
	width: 500px !important;
	padding: 20px;
}

.messageStep1 > span {
	font-size: 25px;
}

/* Step 2 */
.computerStep2 {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.computerStep1Title > div {
	position: absolute;
	display: flex;
	top: 4px;
	right: 4px;
	height: calc(100% - 8px);
	width: 40px;
	justify-content: center;
	align-items: center;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
}

.computerStep2 table,
.computerStep2 thead,
.computerStep2 tbody,
.computerStep2 th,
.computerStep2 td {
	border: none;
	border-collapse: collapse;
}

.computerStep2 table {
	margin: 1%;
}

.computerStep2 th {
	background-color: #CCCCCC;
	padding: 10px;
	text-align: left;
}

.computerStep2 td {
	background-color: rgba(48, 191, 76, 0.25);
	padding: 10px;
	text-align: left;
}

.computerStep2Message {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	top: -5px;
	left: -10px;
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 3px 0px #00AAFF, inset 0px 0px 3px 0px #00AAFF;
	border-radius: 15px;
}
.computerStep2Message > div {
	z-index: 10 !important;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 3px 0px #00AAFF, inset 0px 0px 3px 0px #00AAFF;
}
.computerStep2Message > div > div {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 4px;
	height: 4vh;
	box-shadow: 0px 0px 3px 0px #00AAFF, inset 0px 0px 3px 0px #00AAFF;
}

.computerStep2Shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

.messageStep2 {
	top: 150%;
	left: 20%;
}

/* Step 3 */
.computerStep3Container {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.computerStep3Container > span {
	padding: 0px 10px;
}

.computerStep3Container > div {
	position: relative;
	display: flex;
	width: 60%;
	flex-direction: column;
	align-items: center;
}
.computerStep3Container > div > img {
	max-width: 60%;
	max-height: 100%;
	margin-bottom: 10px;
	object-fit: contain;
}

.computerStep3Container > div:last-of-type > img {
	max-width: calc(150px - 60px);
	max-height: calc(150px - 60px);
	padding: 30px !important;
	background-image: url(/src/ressources/images/visu/ipadBackground.png);
	background-size: 100% 100%;

	opacity: 0.3;
}

.computerStep3Container > div > div {
	position: absolute;
	bottom: -30px;

	width: 50%;
	border: 1px solid #CCCCCC;
	background-color: #F2F2F2;
	padding: 5px;
	border-radius: 5px;

	color: #999999;
}

.computerStep3Image.active {
	cursor: pointer;
	border-radius: 50%;
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

/* Step 4 */
.computerStep4 {
	display: flex;
	flex: 1;
}

.computerStep4Title {
	display: flex;
	position: relative;
	border-bottom: 1px solid #15C193;
}
.computerStep4Title > div:first-of-type {
	display: flex;
	flex: 1;
	padding: 2vh 1vw;
}
.computerStep4Title > div:last-of-type {
	display: flex;
	align-items: center;
}

.computerStep4TitleButton {
	display: flex;
	margin-right: 10px;
	height: calc(100% - 8px);
	width: 40px;
	justify-content: center;
	align-items: center;
	border: 1px solid #CCCCCC;
	border-radius: 3px;

	opacity: 0.5;
}
.computerStep4TitleButton.active {
	opacity: 1;
}
.computerStep4TitleButton > img {
	height: 80%;
	max-width: 70%;
	object-fit: contain;
}

.computerStep4Container {
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 15px;
	overflow: auto;
}
.computerStep4Container > div {
	display: flex;
	align-items: center;
	margin-bottom: 7px;
}
.computerStep4Container > div > img {
	width: 100%;
}

.computerStep4Label,
.computerStep4Checkbox {
	min-width: 12px;
	max-width: 12px;
	min-height: 12px;
	max-height: 12px;

	border: 2px solid #333333;
	background-color: #F2F4F6;
	border-radius: 50%;

	margin-right: 10px;
}
.computerStep4Label.active {
	min-width: 8px;
	max-width: 8px;
	min-height: 8px;
	max-height: 8px;
	border: 4px solid #30BF4C;
	background-color: #30BF4C;
}
.computerStep4Label.active.noBackground {
	background-color: unset;
}

.computerStep4Checkbox {
	border-radius: 2px;
}

/* Step 5 */
.computerStep5 {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.computerStep5 > div {
	background-color: #F2F4F6;
	border: 1px solid #CCCCCC;

	font-weight: unset;
}

.computerStep5Line {
	display: flex;
	margin: 10px;
}

.computerInput {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0 5px;
	align-self: flex-end;
}
.computerInput > span,
.computerStep5Date > span {
	font-weight: bold;
}
.computerInputContainer {
	display: flex;
	padding: 10px;
	margin-top: 3px;
	border-radius: 2px;
	border: 1px solid #CCCCCC;
}

.computerStep5Icon {
	display: flex;
	align-items: flex-end;
}
.computerStep5Icon > div {
	padding: 10px;
	border-radius: 2px;
	border: 1px solid #CCCCCC;
	margin: 0 5px;
}
.computerStep5Icon > div > img {
	height: 14px;
}

.computerStep5Date {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 0 5px;
	align-self: flex-end;
}

.computerStep5DateInput {
	display: flex;
	margin-top: 3px;
}
.computerStep5DateInput.shadow {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 12px 6px yellow; 
	box-shadow: 0px 0px 12px 6px yellow;
}

.computerStep5DateInput > input {
	display: flex;
	flex: 1;
	padding: 10px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	border: 1px solid #CCCCCC;
	outline: none;

	font-size: min(0.8vh, 14px);
    font-weight: bold;
    color: #333333;
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.computerStep5DateInput > div:last-of-type {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: #CCCCCC;
}

.computerStep5DateInput > div:last-of-type > img {
	height: 14px;
}

.computerInputLabel {
	display: flex;
	position: absolute;
	bottom: -20px;
}
.computerInputLabel > div {
	display: flex;
	margin-right: 15px;
}

.computerInputLabel > div > div {
	border-color: #999999 !important;
	margin-right: 5px;
}

.errorStep5 {
	top: 95%;
	left: 0;
}

/* Step 6 */
.computerStep6 {
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: min(1vh, 18px);
}

.computerStep6 > table {
	margin: 3%;
	font-weight: bold;
}

.computerStep6 table {
	border-collapse: collapse;
}

.computerStep6 > table th {
	background-color: #CCCCCC;
	padding: 10px;
	text-align: left;
}

.computerStep6Line {
	height: 1px;
	width: 100%;
	background-color: #CCCCCC;
	margin: 5px 0;
}
.computerStep6Line.empty {
	height: 0px;
}

.computerStep6 > table td {
	text-align: left;
}

.computerStep6TableGreen {
	background-color: #30BF4C;
}
.computerStep6TableGreen > td {
	padding: 10px;
	color: #fff;
}

.computerStep6TableLine {
	height: 1px;
}

.computerStep6TableLine > td {
	height: inherit;
	padding: 5px 10px;
	background-color: #fff;
	border-top: 1px solid #CCCCCC;
	border-bottom: 1px solid #CCCCCC;
}

.computerStep6TableLine > td:first-of-type {
	padding: 0;
	background-color: unset;
	border: none;
}
.computerStep6TableLine > td:first-of-type > div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border: 1px solid #CCCCCC;
	background-color: #fff;
	margin-left: -1px;
	margin-right: 10px;
	border-radius: 3px;
}

.computerStep6TableLineStartBorder {
	border-left: 1px solid #CCCCCC;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.computerStep6TableLine > td:last-of-type {
	border-right: 1px solid #CCCCCC;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.computerStep6SandClock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.computerStep6SandClock > img {
	max-height: 25px;
	border: 1px solid #fff;
	padding: 2px;
	border-radius: 5px;
}

.computerStep6Round {
	display: flex;
	min-width: 16px !important;
	max-width: 16px !important;
	min-height: 16px !important;
	max-height: 16px !important;
	justify-content: center;
	align-items: center;
	background-color: #CCCCCC;
	border: 1px solid #CCCCCC;
	border-radius: 50%;

	font-size: 12px;
	font-weight: normal;
}
.computerStep6Round.blue {
	background-color: unset;
	border-color: #00AAFF;
	color: #00AAFF;
	font-weight: bold;
}
.computerStep6Round.green {
	background-color: #30BF4C;
	border-color: #30BF4C;
	color: #fff;
	font-weight: bold;
}

.computerStep6Product {
	display: flex;
	align-items: center;
}

.computerStep6Product > div:first-of-type {
	margin-right: 10px;
	color: #00AAFF;
}
.computerStep6ProductText {
	color: blue;
}

.computerStep6RoundContainer {
	display: flex;
	margin-top: 10px;
}
.computerStep6RoundContainer > div:first-of-type {
	margin-right: 5px;
}

.computerStep6Input {
	display: flex;
	width: 100%;
	border: 1px solid #999999;
	justify-content: center;
	border-radius: 3px;
	padding: 2px 0;
}

.errorStep6 {
	top: -200%
}

.messageStep6 {
	top: 50%;
	left: 20%;
}

/* Step 7 */
.computerStep7 {
	display: flex;
	flex: 1;
}

.computerStep7Add {
	position: relative;
	cursor: pointer;
	border: 1px solid #CCCCCC;
	padding: 3px 10px;
	margin-right: 10px;
	border-radius: 3px;
}

.computerStep7Container {
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 5px;
}

.computerStep7Line {
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-bottom: 5px;
	border: 1px solid #30BF4C;
	border-radius: 3px;
}
.computerStep7Line > div {
	display: flex;
	align-items: center;
}

.computerStep7Line > div:first-of-type > div:first-of-type {
	min-width: 15px !important;
	max-width: 15px !important;
	min-height: 15px !important;
	max-height: 15px !important;
	background-color: #CCCCCC;
	border-radius: 50%;
}

.computerStep7Text {
	display: flex;
	flex: 1;
	margin-left: 2px;
}

.computerStep7Button {
	display: flex;
	background-color: #30BF4C;
	padding: 10px;
	border-radius: 3px;
	border: 1px solid #30BF4C;

	color: #fff;
}
.computerStep7Button.white {
	margin-left: 10px;
	border-color: #CCCCCC;
	background-color: #fff;
}
.computerStep7Button.shadow {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 12px 6px yellow; 
	box-shadow: 0px 0px 12px 6px yellow;
}
.computerStep7Button.opacity {
	opacity: 0.5;
}

.computerStep7Button > img {
	max-height: 15px;
}

.computerStep7CheckContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
}

.computerStep7CheckContainer > div {
	display: flex;
	align-items: center;
	margin-left: 50%;
	font-weight: normal;
}

.errorStep7Add {
	z-index: 20;
	top: 50%;
}
.errorStep7Date {
	z-index: 20;
	right: -20px;
	top: 20%;
}

.computerStep7Message {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	top: -30%;
	width: calc(100% + 10vh);
	height: 200%;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
	border-radius: 15px;
}
.computerStep7Message > div {
	z-index: 10 !important;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
}
.computerStep7Message > div > div {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 4px;
	height: 4vh;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
}
.messageStep7 {
	top: 90%;
	left: -100px;
}

/* Step 8 */
.computerStep8 {
	display: flex;
	width: 100%;
	flex-direction: column;
	font-size: min(1vh, 16px);
}

.computerStep8Title {
	display: flex;
	width: 100%;
	margin: 2%;
	margin-bottom: 0;
	padding-bottom: 1%;
	border-bottom: 1px solid #333333;

	font-size: min(1.5vh, 18px);
	font-weight: bold;
	color: #333333;
}

.computerStep8 > table {
	margin: 3%;
}

.computerStep8 table {
	border-collapse: collapse;
}

.computerStep8 > table th {
	padding: 10px;
	text-align: center;
}

.computerStep8Line {
	height: 10px;
}

.computerStep8Table {
	text-align: center;
	background-color: #fff;
}
.computerStep8Table > td {
	padding: 10px 0;
	font-weight: 500;
}

.computerStep8PromoIcon {
	display: flex;
	margin: 0 5px;
	justify-content: center;
	align-items: center;
	background-color: #FBFBFC;
	border-radius: 10px;
	padding: 2px 0px;
	font-size: 8px;
	color: #333333;
}
.computerStep8PromoIcon > img {
	height: 14px;
}

.computerStep8BluePrice {
	color: #00AAFF;
}

.step8Message1 {
	top: 50%;
	left: 20%;
}

.computerStep8Message {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	top: 0;
	width: 40px;
	height: 100%;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
	border-radius: 15px;
}
.computerStep8Message > div {
	z-index: 10 !important;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
}
.computerStep8Message > div > div {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 4px;
	height: 4vh;
	box-shadow: 0px 0px 3px 0px yellow, inset 0px 0px 3px 0px yellow;
}

.step8Error1 {
	right: 35vh;
	bottom: 25%;
}

.step8PopupContainer {
	display: flex;
	margin: 0 2%;
}
.step8PopupContainer > div {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.step8Popup {
	-webkit-box-shadow: 5px 5px 12px 1px #CCCCCC; 
	box-shadow: 5px 5px 12px 1px #CCCCCC;

	text-align: center;
	font-size: min(1.2vh, 16px);
}

.step8PopupHeader > div:first-of-type {
	padding: 10px;
	font-weight: bold;
}
.step8PopupHeader > div:last-of-type {
	display: flex;
	justify-content: space-around;
	margin-bottom: 10px;
	color: #CCCCCC;
	font-size: min(0.9vh, 14px);
}
.step8Popup > table {
	padding-top: 10px;
	background-color: #fff;
}

.step8Popup > table th {
	color: #333333;
	font-size: min(0.9vh, 14px);
}

.computerStep8Input {
	display: flex;
	width: 100%;
	border: 1px solid #999999;
	justify-content: center;
	border-radius: 3px;
}

.computerStep8Input > div {
	background-color: #00AAFF;
	padding: 2px 5px;
	color: #fff;
	font-weight: bold;
}
.computerStep8Input > span {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.computerStep8PlusInput.active {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 15px 6px yellow; 
	box-shadow: 0px 0px 15px 6px yellow;
}

.step8Message4 {
	top: 130%;
}

/* Step 9 */
.computerMainStep9 {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	background-color: #F2F2F2;
	border-top-left-radius: 3%;
	border-top-right-radius: 3%;
}

.computerStep9Title {
	padding: 2%;
	border-bottom: 1px solid #CCCCCC;
	
	color: #00AAFF;
	font-weight: bold;
}

.computerStep9Container {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
	align-self: center;
	align-items: center;
	/* justify-content: center; */
}

.computerStep9Container > span {
	font-size: 20px;
	color: #999999;
	margin: 5%;
}
.computerStep9Container > img {
	width: 50px;
}

.computerStep9Facture {
	display: flex;
	width: 100%;
	padding: 10px;
	border: 1px solid #CCCCCC;
	margin-top: 5%;
	border-radius: 5px;
	color: #333333;
	font-weight: bold;
}
.computerStep9Facture > div {
	display: flex;
	flex: 1;
}

.computerStep9Option {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;
	border: 1px solid #00AAFF;
	border-radius: 5px;
	margin-top: 20px;
	color: #333333;
	font-weight: 500;
}
.computerStep9Option > span {
	margin-left: 20px;
}

.computerStep9ButtonContainer {
	display: flex;
	width: 100%;
	margin-top: 5%;
}

.computerStep9Button {
	display: flex;
	flex: 1;
	justify-content: center;
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #999999;

	font-weight: 500;
	color: #CCCCCC;
}
.computerStep9Button.blue {
	position: relative;
	cursor: pointer;
	border: 1px solid #00AAFF;
	background-color: #00AAFF;
	font-weight: bold;
	color: #fff;
}
.computerStep9Button.shadow {
	-webkit-box-shadow: 0px 0px 12px 10px yellow; 
	box-shadow: 0px 0px 12px 10px yellow;
}

.computerStep9Button > img {
	max-height: 20px;
	margin-right: 10px;
}

.computerStep9Message {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	top: -20px;
	width: 110%;
	height: 180%;
	box-shadow: 0px 0px 3px 0px #00AAFF, inset 0px 0px 3px 0px #00AAFF;
	border-radius: 15px;
}
.computerStep9Message > div {
	z-index: 10 !important;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 3px 0px #00AAFF, inset 0px 0px 3px 0px #00AAFF;
}

.step9Message {
	z-index: 10;
	right: -160px;
	bottom: -120px;
}

/* Step 10 */
.step10Modal {
	position: absolute;
	display: flex;
	justify-content: center;
	/* height: 60%; */
	width: 80%;
	top: 30%;
	left: 10%;
	border-radius: 50px;
	background-color: #C5DC23;
	/* background-image: url(/src/ressources/images/pop-up-final/rectangle\ pop\ up.png); */
	background-size: 100% 100%;
	filter: blur(0);
}
.step10Modal > div {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5% 0;
}
.step10Modal > div > span {
	width: 85%;
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	text-align: center;
}
.step10Modal > div > span > img {
	height: min(3vh, 30px);
}
.step10Button {
	background-image: url(/src/ressources/images/pop-up-final/CTA.png);
	background-size: 100% 100%;
	color: #065C7A;
}

.step10Button > div {
	display: flex;
	align-items: center;
	margin: 20px;
	font-weight: bold;
}
.step10Button > div > img {
	margin-left: 10px;
	height: 20px;

	background-image: url(/src/ressources/images/pop-up-final/Ellipse\ 2.png);
}

.step10Arrow {
	cursor: pointer;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 20px;
	right: 20px;
	min-height: 40px;
	max-height: 40px;
	min-width: 40px;
	max-width: 40px;
	border-radius: 50%;
	background-color: #fff;
}

.step10Arrow > img {
	height: 20px;
}

.step10Pourcent {
	position: absolute;
	bottom: -30px;
	right: -30px;
	padding: 8px;
	border: 4px solid #fff;
	border-radius: 50%;
	background-color: #C5DC23;
}

.step10Pourcent > div:first-of-type {
	background-color: #fff;
	border-radius: 50%;
	padding: 4px;
}

.step10Pourcent > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 80px;
	max-height: 80px;
	min-width: 80px;
	max-width: 80px;
	/* border: 1px solid red; */
	box-shadow: 1px 1px 2px 0px rgb(176, 176, 176);
	border-radius: 50%;
	background-color: #fff;
}

.step10Pourcent > div > div > span {
	font-size: 40px;
	font-weight: bolder;
}
.step10Pourcent > div > div > span > span {
	font-size: 20px;
	font-weight: bold;
}

.step10Pourcent > div:last-of-type {
	position: absolute;
	z-index: 10;
	background-color: #fff;
	min-height: 16px;
	max-height: 16px;
	min-width: 16px;
	max-width: 16px;
	border-radius: 50%;
	top: 10px;
	right: 10px;
}