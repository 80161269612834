.App {
	width: 100vw;
	height: 100vh;
}

.appForm {
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
}
.appForm > img {
	width: 100vw;
	height: 100vh;
}

.background {
	z-index: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.background > div {
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(9px);
}

.tableContainer {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-position-y: bottom;
	background-position-x: center;
	background-repeat: no-repeat;
	/* background-size: cover; */
	background-size: 120%;

	overflow: hidden;
}
.table {
	/* width: 100vw; */
	height: 100vh;
	margin-top: 15%;

	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 100%;
}

.table.zoom2 {
	background-size: 120%;
	margin-top: 18%;
	animation-name: table-zoom-2;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.table.zoom3 {
	background-size: 120%;
	margin-top: 22%;
	animation-name: table-zoom-3;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.table.zoom4 {
	background-size: 120%;
	margin-top: 24%;
	animation-name: table-zoom-4;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

@keyframes table-zoom-2 {
	0% {
		background-size: 100%;
		margin-top: 15%;
	}
}

@keyframes table-zoom-3 {
	0% {
		margin-top: 18%;
	}
}

@keyframes table-zoom-4 {
	0% {
		margin-top: 22%;
	}
}

.computer {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: auto 50vh;
}

.computer.zoom1 {
	top: 1%;
	background-size: auto 60vh;
	animation-name: computer-zoom-1;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.computer.zoom2 {
	top: 2%;
	background-size: auto 70vh;
	animation-name: computer-zoom-2;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.computer.zoom3 {
	top: 10%;
	background-size: auto 100vh;
	animation-name: computer-zoom-3;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.computer.zoom4 {
	top: 20%;
	background-size: auto 140vh;
	background-position-y: 50%;
	animation-name: computer-zoom-4;
	animation-duration: 1s;
	animation-iteration-count: 1;
	/* background-color: #fff; */
}

@keyframes computer-zoom-1 {
	0% {
		background-size: auto 50vh;
		top: 0;
	}
}

@keyframes computer-zoom-2 {
	0% {
		background-size: auto 60vh;
		top: 0;
	}
}

@keyframes computer-zoom-3 {
	0% {
		background-size: auto 70vh;
		top: 2%;
	}
}

@keyframes computer-zoom-4 {
	0% {
		background-size: auto 100vh;
		top: 10%;
	}
}

.computerZone {
	position: absolute;
	height: 72.9%;
	width: 120.5vh;
	/* background-color: red; */
	top: 4%;
	left: calc(50vw - 60vh);
	z-index: 6;

	border-top-left-radius: 3%;
	border-top-right-radius: 3%;
	overflow: hidden;

	animation-name: computerZone-zoom-4;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

.computerZone.zoom2 {
	left: calc(50vw - 30.2vh);
	width: 60.5vh;
	top: 42%;
	height: 36.35%;

	animation: unset;
}
.computerZone.zoom3 {
	z-index: 1;
	left: calc(50vw - 43vh);
	width: 86.4vh;
	top: 17%;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	height: 52.2%;

	animation-name: computerZone-zoom-3;
	animation-duration: 1s;
	animation-iteration-count: 1;
}

@keyframes computerZone-zoom-3 {
	0% {
		left: calc(50vw - 30.2vh);
		width: 60.5vh;
		top: 42%;
		height: 36.35%;
	}
}

@keyframes computerZone-zoom-4 {
	0% {
		left: calc(50vw - 43vh);
		width: 86.4vh;
		top: 17%;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		height: 52.2%;
	}
}


.mainContainer {
	position: absolute;
	z-index: 5;
	display: flex;
	flex-direction: column;

	width: 100vw;
	height: 100vh;
}

.header {
	display: flex;
	width: 100%;
	height: 14%;
	max-height: 130px;

	border-bottom: 2px solid #FFFFFF99;
	backdrop-filter: blur(11px);
}

.header > div {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: Montserrat;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #FFFFFF;
}

.headerSoundBar {
	width: 56px;
	height: 2px;
	background-color: #fff;
}

.headerSound > img {
	cursor: pointer;
	height: 40px;
	width: 40px;
	margin-left: 8px;
}

.headerLogo > a > img {
	max-height: 60px;
	margin-left: 50px;
}

.headerLink {
	cursor: pointer;
}

.mainContainer > video {
	z-index: 100;
	position: absolute;
	width: 100vw;
	height: 86%;
	top: 14%;
	background-color: #000;
}

.playButton {
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: calc(50% - 50px);
	width: 100px;
	height: 100px;
	z-index: 101 !important;
	background-image: url(./ressources/images/icon/ic_play.png);
}


.bodyContainer {
	display: flex;
	flex: 1;
	max-height: 76%;
}
.bodyContainer.end {
	justify-content: flex-end;
}
.bodyContainer.spaceBetween {
	justify-content: space-between;
}
.bodyContainer.full {
	max-height: 100%;
	justify-content: space-between;
}

/* Step 1 */

.step1 {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	/* margin-bottom: 20vw; */
	height: 15vw;
	margin-top: 2vw;

	background-image: url(/src/ressources/images/background/homeTextBackground.png);
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
}

.step1 > img {
	max-width: 100%;
	max-height: 100%;
}

.step1 > span {
	margin-top: 15px;

	font-family: Montserrat;
	font-size: 60px;
	font-weight: bold;
	color: #fff;
}

.step1Round {
	position: absolute;
	bottom: 10px;
	left: calc(50% - 2.5vw);
	cursor: pointer;
	width: 5vw;
	height: 5vw;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.3);
	animation-name: bulle-step1;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
}
.step1Round:hover {
	-webkit-box-shadow: 0px 0px 12px 1px #fff; 
	box-shadow: 0px 0px 12px 1px #fff;
}

@keyframes bulle-step1 {
	50% {
		width: 	4.5vw;
		height: 4.5vw;
		left: calv(50% - 2vw);
	}
}

/* Step 2 - 3 */

.step2 {
	display: flex;
	width: 30vw;
	margin-left: 2vw;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
	/* background-color: aqua; */
}

.step2 > div:first-of-type {
	position: relative;
	display: flex;
	align-self: center;
	width: 60%;
	margin-top: 15px;
	
	/* text-align: center; */
	font-family: Montserrat;
	font-size: min(3.8vh, 40px);
	/* font-weight: bold; */
	font-weight: 600;
	color: #fff;
}
.step2 > div:first-of-type > span {
	z-index: 2;
	margin: 10vh 10px;
}
.step2 > div:first-of-type > span > span {
	margin-top: 10px;
	font-size: min(2.5vh, 25px);
	font-weight: normal;
}
.step2 > div:first-of-type > span > img {
	height: min(5vh, 50px);
}
.step2 > div:first-of-type > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

	background-image: url(/src/ressources/images/global/popup.png);
	background-size: 100% 100%;
	/* opacity: 0.4; */
}

.step2 > div:last-of-type {
	cursor: pointer;
	margin-left: 20%;
	margin-top: 20px;
	min-width: 5vw;
	min-height: 5vw;
	max-width: 5vw;
	max-height: 5vw;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.3);
	animation-name: bulle-step2;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
}
.step2 > div:last-of-type:hover {
	-webkit-box-shadow: 0px 0px 12px 1px #fff; 
	box-shadow: 0px 0px 12px 1px #fff;
}

@keyframes bulle-step2 {
	50% {
		min-width: 	4.5vw;
		min-height: 4.5vw;
		max-width:	4.5vw;
		max-height: 4.5vw;
		margin-top: calc(20px + 0.5vw);
		/* margin-left: calc(20% + 0.5vw); */
	}
}

/* Step 4 */

.step4 {
	position: relative;
	display: flex;
	width: 25vw;
	margin-left: 1vw;
	align-self: flex-end;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* background-color: rgba(255, 255, 255, 0.2); */
}
.step4.last {
	align-self: center;
}

.step4 > div:first-of-type {
	position: relative;
	display: flex;
	align-self: center;
	width: 70%;
	margin: 5vh 10px;
	
	/* text-align: center; */
	font-family: Montserrat;
	font-size: min(3.8vh, 40px);
	/* font-weight: bold; */
	font-weight: 600;
	color: #fff;

	/* background-color: #454F63; */
}

.step4 > div:first-of-type > span {
	z-index: 2;
	margin: 10vh 10px;
}
.step4 > div:first-of-type > span > span {
	margin-top: 10px;
	font-size: min(2.5vh, 25px);
	font-weight: normal;
}
.step4 > div:first-of-type > span > span > img {
	height: min(5vh, 50px);
}

.step4.first > div:last-of-type {
	position: absolute;
	cursor: pointer;
	top: -3vw;
	right: -1vw;
	width: 5vw;
	height: 5vw;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.3);
	animation-name: bulle-step4;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
}
.step4.first > div:last-of-type:hover {
	-webkit-box-shadow: 0px 0px 12px 1px #fff; 
	box-shadow: 0px 0px 12px 1px #fff;
}

@keyframes bulle-step4 {
	50% {
		width: 	4.5vw;
		height: 4.5vw;
	}
}

.step4.last > div:last-of-type {
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	bottom: -8vh;
	width: 60%;
	height: 22vh;
}

.step4 > div:first-of-type > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

	background-image: url(/src/ressources/images/global/popup.png);
	background-size: 100% 100%;
	/* opacity: 0.4; */
}

.step4Button {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 25px 35px;

	background-image: url(/src/ressources/images/global/intro-button.png);
	background-size: 100% 100%;
}
.step4Button:hover {
	margin-bottom: -1px;
	padding: 27px 37px;
}
.step4Button > span {
	text-align: center;
	font-size: min(2vh, 22px);
	font-weight: bold;
	color: #454F63;
}
.step4Button > div {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 20px;
	min-height: 20px;
	max-width: 20px;
	max-height: 20px;
	padding: 5px;
	margin-left: 5px;
	border-radius: 50%;
	background-image: url(/src/ressources/images/global/intro-button-iconbox.png);
	background-size: 100% 100%;
}
.step4Button > div > img {
	max-width: 100%;
	max-height: 100%;
}

/* step 5 - 8 */
.step5 {
	position: relative;
	display: flex;
	width: calc(50vw - 63vh);
	margin-left: 1vh;
	flex-direction: column;
	/* align-items: center; */
	justify-content: flex-end;
	/* background-color: aqua; */
	overflow: hidden;
	/* padding-bottom: 20vh; */
}

.step5 > div {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.step5Box {
	max-height: 80px;
	margin-left: 10px;
}

.step5Carte {
	position: relative;
	margin-bottom: 20px;
	margin-right: 10px;
	max-height: 80px;
}

.step5Ordonance {
	/* position: absolute; */
	margin: 0 10px;
}

.step5 img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.step5Box.active > img,
.step5Carte.active > img,
.step5Ordonance.active > img,
.step5OrdonanceHover.active {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 12px 6px yellow; 
	box-shadow: 0px 0px 12px 6px yellow;
}

.step5Box > div,
.step5Carte > div,
.step5Ordonance > div {
	position: absolute;
	width: 50px;
	top: -25px;
	left: calc(50% - 25px);
}
.step5Box > div {
	left: 10px;
}

.step5OrdonanceHover {
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(50vw - 50vh);
	z-index: 10;
}

.step5Scanner {
	position: relative;
	display: flex;
	align-self: flex-end;
	width: calc(50vw - 63vh);
	margin-right: 1vh;
	flex-direction: column;
	/* align-items: center; */
	justify-content: flex-end;
	/* background-color: aqua; */
	overflow: hidden;
}
.step5Scanner > img {
	max-width: 100%;
	max-height: 300px;
	object-fit: contain;
}

/* step11 */
.step11 {
	position: relative;
	display: flex;
	width: calc(50vw - 63vh);
	margin-left: 1vh;
	flex-direction: column;
	/* align-items: center; */
	justify-content: flex-end;
	/* background-color: aqua; */
	overflow: hidden;
	/* padding-bottom: 20vh; */
}

.step11 > div {
	position: relative;
	display: flex;
	justify-content: space-between;
}

/* .step11item {
	position: relative;
	max-width: 100%;
	height: 100%;
	margin: 0 10px;
}

.step11item > img,
.step11item > div > img {
	max-width: 100%;
	background-image: url(/src/ressources/images/visu/sunscreen-light.png);
	background-position: center;
	background-size: 200% 200%;
}

.step11item > div {
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 10%;
	left: 30%;
}

.step11item > div:last-of-type {
	bottom: 0;
	left: 0;
} */

.step11itemContainer {
	position: absolute;
	left: 0;
	top: 0;
	height: 78vh;
	width: calc(50vw - 61vh);
	/* width: calc(50vw - 100vh); */
	/* background-color: #454F63; */
}
.step11itemContainer > div {
	position: relative;
	/* display: flex; */
	width: 100%;
	height: 100%;
	/* background-color: antiquewhite; */
}

.step11item {
	position: absolute;
	max-width: 100%;
	max-height: 100%;
	left: -30%;
	bottom: 0;
	/* background-color: red;
	opacity: 0.5; */
}

.step11item:last-of-type {
	left: unset;
	right: -30%;
	/* background-color: #454F63; */
}

.step11item > img,
.step11item > div > img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	/* background-color: aquamarine; */
}

.step11item > div {
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 10%;
	left: 10%;
}

.step11item > div:last-of-type {
	bottom: 0;
	left: 0;
}



.step11Card {
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 0 10px;
}
.step11Card > img {
	max-height: 200px;
	max-width: 100%;
}

.step11item.active,
.step11Card.active > img {
	cursor: pointer;
	border-radius: 10px;
	overflow: hidden;
}

.step11Ordonance.active {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 12px 6px yellow; 
	box-shadow: 0px 0px 12px 6px yellow;
}

/* Cookie Popup */
.appModal {
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.13);
}

.messageCookie {
	border-radius: 20px !important;
	min-width: 50%;
	padding-top: 50px;
	background-color: #fff;
	background-image: none;
	color: #454F63 !important;
}

.messageCookie > div {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}


.legal {
	position: relative;
	border-radius: 20px !important;
	max-width: 90%;
	max-height: 80%;
	padding: 20px;
	font-size: 11px;
	background-color: #fff;
	background-image: none;
	color: #005978 !important;
	font-family: Nunito;
	overflow-x: scroll;
	text-align: justify;
}

.legal > div {
	text-align: center;
	font-size: 22.5px;
	font-weight: bold;
	margin-bottom: 20px;
	margin-top: 20px
}

.legal > div:first-of-type {
	position: absolute;
	right: 20px;
	top: 10px;
	cursor: pointer;
}